export const PHARMGKB_URL_PREFIX = 'https://';

export const CPIC_FULL = 'Clinical Pharmacogenetics Implementation Consortium';
export const CPIC_URL = 'https://cpicpgx.org';

export const DPWG_FULL = 'Dutch Pharmacogenetics Working Group';
export const DPWG_URL = 'https://www.pharmgkb.org/page/dpwg';

export const FDA_FULL = 'Food and Drug Administration';
export const FDA_URL = 'https://www.fda.gov';

export const FDAPGX_FULL = 'Food and Drug Administration Table of Pharmacogenetic Associations';
export const FDAPGX_URL = 'https://www.fda.gov/medical-devices/precision-medicine/table-pharmacogenetic-associations';

export const PGX_FULL = 'Pharmacogenetics';
