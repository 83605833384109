import { useMemo } from 'react';

import dayjs from 'dayjs';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';

import IconButton from '@components/Button/IconButton';
import { ORDER_STATUS_MAPPING } from '@constants/constants';
import { ResultsType } from '@customTypes/app';
import { ExtendedOrder, OrderStatus } from '@customTypes/order';
import { getOrderResult } from '@helpers/order';
import { getPatientOrderTestName } from '@helpers/patient';
import { useDownload } from '@hooks/useDownload';

interface IProps {
  order: ExtendedOrder;
  requisitionId: string;
}

export default function PatientDetailOrderCard({ order, requisitionId }: IProps) {
  const { onDownload } = useDownload();

  const colorVariant = useMemo(() => (order.isReported ? 'primary' : 'light'), [order.isReported]);
  const isCanceled = useMemo(() => order.status === OrderStatus.CANCELED, [order.status]);
  const orderTestNames = useMemo(() => getPatientOrderTestName(order.product_name), [order.product_name]);
  const orderHeaderIcon = useMemo(() => {
    const result = getOrderResult(order);
    let icon;

    if (result === ResultsType.ACTIONABLE_RESULT) {
      icon = faDiamondExclamation;
    }
    if (result === ResultsType.AVERAGE_RISK_OR_NEGATIVE) {
      icon = faCircle;
    }

    return icon;
  }, [order]);

  return (
    <Card border={colorVariant} className='border patient-detail-order-card' bg={colorVariant}>
      <Card.Header className={`order-test-status ${colorVariant === 'light' ? 'text-dark' : 'text-white'}`}>
        {!!orderHeaderIcon && (
          <FontAwesomeIcon
            icon={orderHeaderIcon as IconDefinition}
            color={`${colorVariant} text-center`}
            className='me-2'
            fontSize={16}
          />
        )}
        {ORDER_STATUS_MAPPING[order.status]?.label}
      </Card.Header>
      <Card.Body className={`d-flex flex-column align-items-start gap-2 ${isCanceled ? 'bg-tertiary' : 'bg-white'}`}>
        <div className='d-flex flex-column align-items-start order-test-name'>
          {!!orderTestNames.brand && <p className='fs-body fw-medium text-second'>{orderTestNames.brand}</p>}
          {!!orderTestNames.productLine && <p className='fs-5 lh-xs fw-medium'>{orderTestNames.productLine}</p>}
          {!!orderTestNames.productName && <p className='fs-6 lh-xs fw-medium'>{orderTestNames.productName}</p>}
        </div>
        <p className='fs-body mb-0'>
          {order.reported_at && order.isReported && `Report date: ${dayjs.utc(order.reported_at).format('MM/DD/YYYY')}`}
          {!order.isReported && order.created_at && `Order date: ${dayjs.utc(order.created_at).format('MM/DD/YYYY')}`}
        </p>
        {order.isReported && (
          <IconButton
            startIcon={faCircleArrowDown}
            size='sm'
            variant='outline-primary'
            className='mt-1'
            onClick={() => onDownload(order, requisitionId)}
          >
            Results
          </IconButton>
        )}
      </Card.Body>
    </Card>
  );
}
