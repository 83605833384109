import { useContext, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faCircleCheck, faPrint } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';

import IconButton from '@components/Button/IconButton';
import RequisitionReceiptContent from '@components/Requisition/RequisitionReceiptContent/RequisitionReceiptContent';
import { ORDERS_AND_REPORTS } from '@constants/routes';
import usePatientDetail from '@hooks/usePatientDetail';
import { Context } from '@store/store';

import './RequisitionComplete.scss';

const RequisitionComplete = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);

  const contentRef = useRef<HTMLDivElement | null>(null);
  const newRequisition = useMemo(() => state.newRequisition?.requisition, [state.newRequisition]);
  const patientId = useMemo(() => state.newRequisition?.patientId, [state.newRequisition]);
  const { patientName } = usePatientDetail(patientId);

  const moveToRequisitions = () => {
    navigate(ORDERS_AND_REPORTS);
  };

  const moveToPatientDetail = () => {
    navigate(`/patients/${patientId}`);
  };

  const printConfirmation = () => {
    window.print();
  };

  const downloadPDF = async () => {
    const element = contentRef.current;
    if (!element) return;

    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'in', 'letter');
    const pdfWidth = pdf.internal.pageSize.getWidth() - 0.4;
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0.2, 0.5, pdfWidth, pdfHeight);

    // Create a Blob from the PDF
    const pdfBlob = pdf.output('blob');

    FileSaver.saveAs(pdfBlob, 'Order Confirmation.pdf');
  };

  useEffect(() => {
    return () => {
      dispatch({ type: 'SET_NEW_REQUISITION', payload: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!newRequisition) {
    return null;
  }

  return (
    <Stack
      gap={4}
      className='layout-single-col-py requisition-complete-container'
      data-testid='requisitionCompletePage'
    >
      <Breadcrumb listProps={{ className: 'mb-0' }}>
        {!!patientId && <Breadcrumb.Item onClick={moveToPatientDetail}>{patientName}</Breadcrumb.Item>}
        {!patientId && <Breadcrumb.Item onClick={moveToRequisitions}>Orders and Reports</Breadcrumb.Item>}
        <Breadcrumb.Item active>Order Complete</Breadcrumb.Item>
      </Breadcrumb>
      <section className='d-flex flex-column bg-white px-md-4 pb-5 pt-md-2 box-shadow-50 rounded-1'>
        <FontAwesomeIcon icon={faCircleCheck} fontSize={40} className='me-2 d-print-none' />
        <h1 className='mb-0 fw-semibold fs-3'>Order Confirmation</h1>
        <div className='p-4 pb-0 d-flex flex-column align-items-center gap-4'>
          <RequisitionReceiptContent ref={contentRef} requisition={newRequisition} />
          <Stack
            direction='horizontal'
            gap={3}
            className='flex-column flex-md-row justify-content-center mt-md-3 requisition-complete-actions d-print-none'
          >
            <IconButton variant='outline-primary' onClick={moveToRequisitions} data-testid='returnToRequisitionsBtn'>
              Orders and Reports
            </IconButton>
            <IconButton
              variant='outline-primary'
              startIcon={faPrint}
              onClick={printConfirmation}
              data-testid='printConfirmationBtn'
            >
              Print Confirmation
            </IconButton>
            <IconButton variant='outline-primary' startIcon={faCircleArrowDown} onClick={downloadPDF}>
              Download PDF
            </IconButton>
          </Stack>
        </div>
      </section>
    </Stack>
  );
};

export default RequisitionComplete;
