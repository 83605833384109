import { Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

import InfoDialog from '@components/Modals/InfoDialog';
import PGxGuidanceBadge from './PGxGuidanceBadge';

import {
  CPIC_FULL,
  CPIC_URL,
  DPWG_FULL,
  DPWG_URL,
  FDA_FULL,
  FDA_URL,
  FDAPGX_FULL,
  FDAPGX_URL,
} from '@constants/pgxguidance';

const sourceList = [
  {
    acronym: 'CPIC',
    name: CPIC_FULL,
    url: CPIC_URL,
  },
  {
    acronym: 'DPWG',
    name: DPWG_FULL,
    url: DPWG_URL,
  },
  {
    acronym: 'FDA',
    name: FDA_FULL,
    url: FDA_URL,
  },
  {
    acronym: 'FDA PGx',
    name: FDAPGX_FULL,
    url: FDAPGX_URL,
  },
];

interface IProps {
  onClose: () => void;
}

export default function PGxGuidanceInfoDialog({ onClose }: IProps) {
  return (
    <InfoDialog
      onClose={onClose}
      title='PGx Medication Guidelines'
      modalProps={{
        id: 'guidanceInfoDialog',
        className: 'guidance-info-dialog',
        size: 'lg',
      }}
    >
      <Stack gap={3} className='p-3 pt-2'>
        <p className='mb-0 fs-body'>
          Providers may use these guidelines as a reference, along with other clinical factors, to help them when
          selecting medications and dosages for this patient. This guideline information is not intended to be used in
          isolation, and the provider needs to take into account all clinical considerations and FDA prescribing
          information before making any changes to treatment.
        </p>
        <div>
          <p className='fw-bold mb-1'>Guideline Sources:</p>
          <ul className='mb-0 ps-2 fs-sm source-list'>
            {sourceList.map((s, index) => (
              <li key={'s.acronym_' + index}>
                <span className='fw-medium'>
                  <abbr>{s.acronym}</abbr>:
                </span>{' '}
                <a href={s.url} target='_blank' rel='nofollow noreferrer'>
                  {s.name}
                </a>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare as IconDefinition}
                  fontSize={12}
                  className='text-primary ms-1'
                />
              </li>
            ))}
          </ul>
        </div>

        <div>
          <p className='fw-bold mb-1'>Guideline Annotation Tags:</p>
          <ul className='mb-0 ps-2 tag-list'>
            <li>
              <div className='mb-1 w-25'>
                <PGxGuidanceBadge guidanceType='dosingInformation' />
              </div>
              <p className='fs-sm mb-0'>
                <strong>Dosing Information:</strong> The guideline recommends a dose adjustment based on genetic
                variants or phenotypes (e.g. &quot;poor metabolizers&quot;). However, the guideline may also describe
                extenuating circumstances where this action is not appropriate.
              </p>
            </li>
            <li>
              <div className='mb-1 w-25'>
                <PGxGuidanceBadge guidanceType='alternateDrugAvailable' />
              </div>
              <p className='fs-sm mb-0'>
                <strong>Alternate Medication Available:</strong> The guideline recommends that an alternate drug be
                selected, or contraindicates the selection of a particular drug, for patients based on genetic variants
                or phenotypes (e.g. &quot;poor metabolizers&quot;). However, the guideline may also describe extenuating
                circumstances where this action is not appropriate.
              </p>
            </li>
            <li>
              <div className='mb-1 w-25'>
                <PGxGuidanceBadge guidanceType='otherPrescribingGuidance' />
              </div>
              <p className='fs-sm mb-0'>
                <strong>Other Prescribing Guidance:</strong> The guideline recommends an action other than an alternate
                drug or change in dosage, based on genetic variants or phenotypes (e.g. &quot;poor metabolizers&quot;).
                However, the guideline may also describe extenuating circumstances where this action is not appropriate.
              </p>
            </li>
          </ul>
        </div>
      </Stack>
    </InfoDialog>
  );
}
