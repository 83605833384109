import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { faPrescription } from '@fortawesome/pro-duotone-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface PGxButtonProps {
  handleClick: () => void;
}
export default function PGxButton({ handleClick }: PGxButtonProps) {
  const [loading, setLoading] = useState(false);
  return (
    <Stack direction='vertical' gap={4} className='pgx-cta-wrapper py-5 px-3'>
      <p className='text-center fw-medium fs-5 lh-xs mb-0'>
        There is guidance available for your patient’s test results.
      </p>
      {loading ? (
        <Button variant='primary' disabled={loading}>
          <FontAwesomeIcon icon={faSpinner} className='fa-spin' />
          &nbsp;Please wait while we gather the most up to date medication information
        </Button>
      ) : (
        <Button
          variant='primary'
          onClick={() => {
            setLoading(true);
            handleClick();
          }}
        >
          <FontAwesomeIcon icon={faPrescription as IconDefinition} className='me-2' />
          Get started
        </Button>
      )}
    </Stack>
  );
}
