import { FC, useEffect, useState } from 'react';
import IconButton from '@components/Button/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faRotate, faSpinner } from '@fortawesome/free-solid-svg-icons';

import ExpandableSection from '@components/ExpandableSection/ExpandableSection';

import NoDetailContent from '../NoDetailContent/NoDetailContent';
import PGxGuidance from './PGxGuidance';
import PGxGuidanceInfoDialog from './PGxGuidanceInfoDialog';

import './PatientDetailPGx.scss';
import { getMedicationGuidance } from '@store/actions';
import PGxButton from '@pages/patientDetail/components/PatientDetailPGx/PGxButton';
import { Guidance } from '@customTypes/drugs';
import { SUPPORT_EMAIL } from '@constants/constants';
import { Button, Stack } from 'react-bootstrap';

interface ErrorDetailsProps {
  onRetry: () => void;
  // We don't really care how many times the api call has failed in this context.
  // Here we use a change in the count to trigger stopping the progress spinner.
  failures: number;
}
const ErrorDetails: FC<ErrorDetailsProps> = ({ onRetry, failures }) => {
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    setReloading(false);
  }, [failures]);

  const classes = reloading ? 'align-self-center fa-spin' : 'align-self-center';
  return (
    <Stack direction='vertical' gap={3} className='pgx-cta-wrapper py-5 px-3'>
      <h5 className='fs-5 lh-sm fw-medium'>Unable to load guidance</h5>
      <p className='lh-base fs-6 mb-0'>
        <span className='d-block'>Please retry or return later.</span>
        <span className='d-block'>
          <span className='d-inline-block align-middle'>If the problem recurs, please contact</span>
          <Button
            variant='link'
            className='p-0 border-0 text-dark ms-1'
            href={`mailto:${SUPPORT_EMAIL}`}
            target='_blank'
          >
            {SUPPORT_EMAIL}.
          </Button>
        </span>
        <span className='d-block'>We’re sorry for the inconvenience.</span>

        <small className='d-inline-block fs-body fw-bold my-2'>500: Internal Server Error</small>
      </p>
      {reloading ? (
        <Button variant='primary' disabled={reloading}>
          <FontAwesomeIcon icon={faSpinner} className='fa-spin' />
          &nbsp;Please wait while we gather the most up to date medication information
        </Button>
      ) : (
        <IconButton
          startIcon={faRotate}
          className={classes}
          variant='primary'
          onClick={() => {
            setReloading(true);
            onRetry();
          }}
        >
          Retry
        </IconButton>
      )}
    </Stack>
  );
};

interface IProps {
  patientId: string;
  hasGuidance: boolean;
}

const PatientDetailPGx = ({ patientId, hasGuidance }: IProps) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [guidance, setGuidance] = useState<Guidance | null>(null);
  const [failures, setFailures] = useState(0);

  const handleClick = async () => {
    try {
      const result = await getMedicationGuidance(patientId);
      setGuidance(result);
      setLoadingError(false);
    } catch (error) {
      console.error('Error loading Medication Guidance', error);
      setLoadingError(true);
      setFailures(failures + 1);
    }
  };

  return (
    <>
      <ExpandableSection
        id='pgxGuidance'
        expandableContentId='pgxGuidanceContent'
        headerTitle={
          <>
            <span>PGx Medication Guidelines</span>
            <Button
              size='sm'
              variant='ghost'
              className='ms-1'
              onClick={() => setShowInfoDialog(true)}
              aria-label='Medication Guidelines Info Button'
            >
              <FontAwesomeIcon icon={faCircleInfo} fontSize={16} />
            </Button>
          </>
        }
      >
        {hasGuidance ? (
          <>
            {!!guidance && <PGxGuidance guidance={guidance} />}
            {!guidance &&
              (loadingError ? (
                <ErrorDetails onRetry={handleClick} failures={failures} />
              ) : (
                <PGxButton handleClick={handleClick} />
              ))}
          </>
        ) : (
          <NoDetailContent content='Guidance may be available for completed orders' />
        )}
      </ExpandableSection>
      {showInfoDialog && <PGxGuidanceInfoDialog onClose={() => setShowInfoDialog(false)} />}
    </>
  );
};

export default PatientDetailPGx;
