import { ResultsType } from '@customTypes/app';
import { Order } from '@customTypes/order';

import { COMPLETED_ORDER_STATUSES } from '@constants/constants';
import { getResultType } from './common';

export const isOrderReported = (order: Order): boolean => {
  return COMPLETED_ORDER_STATUSES.includes(order.status);
};

export const getOrderResult = (order?: Order | null): ResultsType => {
  return getResultType(order?.results ?? []);
};
