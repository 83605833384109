import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

import PGxGuidanceAccordion from './PGxGuidanceAccordion';

import { Annotation } from '@customTypes/drugs';
import { PHARMGKB_URL_PREFIX } from '@constants/pgxguidance';

interface IProps {
  annotation: Annotation;
  actionableWithoutRecommendation: boolean;
}

export default function PGxGuidanceAnnotation({ annotation, actionableWithoutRecommendation }: IProps) {
  if (annotation.notActionable && !actionableWithoutRecommendation) {
    return null;
  }
  return (
    <div className='mb-4 pb-2'>
      {actionableWithoutRecommendation ? 'See Source link.' : <PGxGuidanceAccordion annotation={annotation} />}
      <p className='fs-xs lh-sm mt-2 mb-0'>
        <strong>Source:</strong>{' '}
        <a href={PHARMGKB_URL_PREFIX + annotation.linkUrl} target='_blank' rel='nofollow noreferrer'>
          {annotation.linkUrl}
        </a>
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare as IconDefinition}
          fontSize={12}
          className='text-primary ms-1'
        />
      </p>
    </div>
  );
}
