import PageContainer from '@components/Layout/PageContainer/PageContainer';
import RequisitionCreatePageContainer from '@components/RequisitionCreate/PageContainer';
import { DRAFT_ORDERS, MANAGE_USERS, MY_DELEGATES } from '@constants/routes';
import AccountReview from '@pages/account-review/AccountReview';
import DraftOrders from '@pages/draftOrders/DraftOrders';
import ErrorPage from '@pages/error/errorPage';
import Login from '@pages/login/login';
import UserManagement from '@pages/manage-users/UserManagement';
import MyDelegates from '@pages/my-delegates/MyDelegates';
import OrderNewReport from '@pages/patientDetail/OrderNewReport';
import PatientDetail from '@pages/patientDetail/PatientDetail';
import RequisitionComplete from '@pages/requisitionComplete/RequisitionComplete';
import RequisitionCreate from '@pages/requisitionCreate/RequisitionCreate';
import RequisitionsPage from '@pages/requisitions/Requisitions';
import Signup from '@pages/signup/Signup';

export type RoutePath =
  | '/requisitions'
  | '/'
  | '/login'
  | '/signup'
  | '/account-review'
  | '/error'
  | '/requisitions/create'
  | '/requisitions/complete'
  | '/patients/:id'
  | '/patients/:id/order-new-report/:reqId'
  | '/my-delegates'
  | '/manage-users'
  | '/draft-orders'
  | '*';

export interface PageRoute {
  path: RoutePath;
  element: React.ReactNode;
  PageContainer?: ({ children }: { children: React.ReactNode }) => React.ReactElement;
  newLayout?: boolean;
  keepOnError?: boolean; // Do not navigate to a generic error page on API error
  hide?: boolean;
  private?: boolean; // True for protected routes
  onlyAuth?: boolean; // True for auth pages
  internalUsers?: boolean; // True for display route to internal users only
}

const routes: PageRoute[] = [
  {
    path: '/requisitions',
    element: <RequisitionsPage />,
    newLayout: true,
    keepOnError: true,
    private: true,
  },
  {
    path: '/requisitions/create',
    element: <RequisitionCreate />,
    PageContainer: RequisitionCreatePageContainer,
    newLayout: true,
    keepOnError: true,
    private: true,
  },
  {
    path: '/requisitions/complete',
    element: <RequisitionComplete />,
    newLayout: true,
    private: true,
  },
  {
    path: '/patients/:id',
    element: <PatientDetail />,
    PageContainer,
    newLayout: true,
    private: true,
  },
  {
    path: '/patients/:id/order-new-report/:reqId',
    element: <OrderNewReport />,
    PageContainer: RequisitionCreatePageContainer,
    newLayout: true,
    private: true,
  },
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/login',
    element: <Login />,
    onlyAuth: true,
  },
  {
    path: '/signup',
    element: <Signup />,
    onlyAuth: true,
    newLayout: true,
    keepOnError: true,
  },
  {
    path: '/account-review',
    element: <AccountReview />,
    onlyAuth: true,
    newLayout: true,
  },
  {
    path: MY_DELEGATES,
    element: <MyDelegates />,
    newLayout: true,
    keepOnError: true,
    private: true,
  },
  {
    path: MANAGE_USERS,
    element: <UserManagement />,
    newLayout: true,
    keepOnError: true,
    private: true,
  },
  {
    path: DRAFT_ORDERS,
    element: <DraftOrders />,
    newLayout: true,
    private: true,
    internalUsers: true,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
];

export default routes;
