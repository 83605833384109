import { forwardRef, ReactNode, useMemo } from 'react';
import { ListGroup } from 'react-bootstrap';
import { startCase, kebabCase } from 'lodash';

import { Requisition } from '@customTypes/requisition';
import { getRequisitionName } from '@helpers/requisition';
import useRequisition from '@hooks/useRequisition';

import './RequisitionReceiptContent.scss';

interface IProps {
  requisition: Requisition;
}

interface RequisitionFieldToRender {
  label: string;
  value: string | ReactNode[];
  unordered?: boolean;
  dataTestId?: string;
}

const RequisitionReceiptContent = forwardRef<HTMLDivElement, IProps>(({ requisition }, ref) => {
  const { clinicians, patientName, requisitionId, requisitionDate, orders } = useRequisition(requisition);

  const fieldsToRender: RequisitionFieldToRender[] = useMemo(() => {
    if (!requisition) return [];

    const res: RequisitionFieldToRender[] = [
      {
        label: 'Order ID',
        value: requisitionId,
        dataTestId: 'requisitionNumber',
      },
      {
        label: 'Order Date',
        value: requisitionDate,
        dataTestId: 'orderDate',
      },
      {
        label: clinicians.length > 1 ? 'Clinicians' : 'Clinician',
        value: clinicians.map(clinician => clinician.formattedName),
        dataTestId: 'clinicians',
      },
      {
        label: 'Patient',
        value: patientName,
        dataTestId: 'patient',
      },
    ];

    if (requisition.case_requisitions?.length) {
      res.push({
        label: requisition.case_requisitions?.length > 1 ? 'Related Orders' : 'Related Order',
        value: requisition.case_requisitions.map(caseRequisition => (
          <>
            <p className='mb-0'>
              <span className='fw-medium'>ID: </span>
              {caseRequisition.requisition_id}
            </p>
            <p className='mb-0'>
              <span className='fw-medium'>Name: </span>
              {getRequisitionName(caseRequisition)}
            </p>
            <p className='mb-0'>
              <span className='fw-medium'>Relation: </span>
              {startCase(kebabCase(caseRequisition.relationship))}
            </p>
          </>
        )),
        unordered: true,
      });
    }

    res.push({
      label: orders?.length > 1 ? 'Tests' : 'Test',
      value: orders.map(order => order.product_name),
      unordered: true,
      dataTestId: 'tests',
    });

    return res;
  }, [requisition, clinicians, requisitionId, patientName, requisitionDate, orders]);

  return (
    <div ref={ref} className='requisition-receipt-content-wrapper'>
      <ListGroup as='ul' className='mx-auto requisition-receipt-content'>
        {fieldsToRender.map(field => {
          const isArray = Array.isArray(field.value);
          const unordered = field.unordered && field.value?.length > 1;

          return (
            <ListGroup.Item key={field.label} as='li' data-testid={field.dataTestId}>
              <p className='mb-0 fs-6 fw-semibold field-label'>{field.label}</p>
              {!isArray && <span className='field-value'>{field.value}</span>}
              {isArray && unordered && (
                <ul className='field-value unordered-receipt-item' data-testid='unorderedField'>
                  {(field.value as ReactNode[]).map((item, index) => (
                    <li key={index.toString()}>{item}</li>
                  ))}
                </ul>
              )}
              {isArray && !unordered && (
                <div className='field-value'>
                  {(field.value as string[]).map(item => (
                    <span key={item} className='d-block'>
                      {item}
                    </span>
                  ))}
                </div>
              )}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
});

RequisitionReceiptContent.displayName = 'RequisitionReceiptContent';
export default RequisitionReceiptContent;
