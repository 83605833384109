import { AxiosRequestConfig } from 'axios';

import { Clinician, OrganizationRole } from '@customTypes/clinician';
import { ICreateDraftOrderPayload, IDraftOrder } from '@customTypes/draftOrder';
import { Guidance } from '@customTypes/drugs';
import { OrderResult } from '@customTypes/order';
import { IPatientDetailRes, PatientRequisitionDetails } from '@customTypes/patient';
import { Requisition, Rerequisition } from '@customTypes/requisition';
import { ISignupForm } from '@customTypes/user';
import { setOrganizationRoleFromClinic, setOrganizationRoles } from '@helpers/clinician';
import axios from '@hooks/useAxios';

export const fetchRequisitions = async (): Promise<Requisition[]> => {
  return axios.get('/requisition', { params: { per_page: '500' } }).then(res => res.data.results);
};

export const fetchRequisitionById = async (requisitionId: string): Promise<Requisition> => {
  return axios.get(`/requisition/${requisitionId}`).then(res => res.data);
};

// TODO: replace the unknown with an actual type.
export const placeOrder = async (payload: unknown) => {
  return axios.post('/requisition', payload);
};

export const fetchRerequisition = async (requisitionId: string): Promise<Rerequisition> => {
  return axios.get(`/requisition/${requisitionId}/rerequisition/`).then(res => res.data);
};

export const getOrderResultsById = async (orderId: string): Promise<OrderResult[]> => {
  return axios.get(`/order/${orderId}`).then(res => res.data.results);
};

export const fetchLoggedInUserId = async (): Promise<string> => {
  return axios.get('/profile').then(res => res.data.identifiers.clinician_id);
};

export const fetchCurrentClinician = async (id: string): Promise<Clinician> => {
  return axios.get(`/clinician/${id}`).then(res => setOrganizationRoles(res.data));
};

export const fetchAllClinicians = async (): Promise<Clinician[]> => {
  return axios.get('/clinician').then(res => res.data.results);
};

export const fetchProductsList = async () => {
  return axios.get('/product').then(res => res.data);
};

export const fetchBaseRequisitionJSONSchema = async (
  productIds: string[],
  config: AxiosRequestConfig = {},
  campaignCode?: string,
  rerequisitionId?: string,
) => {
  const params: Record<string, string> = {
    product_ids: productIds.join(','),
  };

  if (campaignCode) {
    params.campaign_code = campaignCode;
  }
  if (rerequisitionId) {
    params.rerequisition = rerequisitionId;
  }

  return axios.get('/provider/forms/requisition', { ...config, params }).then(res => res.data);
};

export const submitRequisitionJSONSchemaForm = async (data: unknown): Promise<Requisition> => {
  return axios.post('/provider/forms/requisition', data).then(res => res.data);
};

export const getMedicationGuidance = async (individual: string): Promise<Guidance> => {
  return axios.get(`/provider/individual/${individual}/medication-response/guidelines`).then(res => res.data);
};

export const getIndividualDetail = async (individual: string): Promise<PatientRequisitionDetails> => {
  return axios.get(`/provider/individual/${individual}`).then(res => res.data);
};

export const getPatientDetail = async (patientId: string): Promise<IPatientDetailRes> => {
  return axios.get(`/patient/${patientId}`).then(res => res.data);
};

export const postOnboarding = async (data: ISignupForm): Promise<string> => {
  // Remove id field from teammates because it's not acceptable in the payload
  return axios
    .post('/provider/onboarding', {
      ...data,
      teammates: data.teammates.map(mate => {
        delete mate.id;

        return mate;
      }),
    })
    .then(res => res.data);
};

export const fetchOrganizationProviders = async (clinicId: string): Promise<Clinician[]> => {
  return axios
    .get(`/provider/organization/${clinicId}/provider`)
    .then(res => setOrganizationRoleFromClinic(res.data.results, clinicId));
};

export const fetchClinicalTeamByProvider = async (clinicId: string, providerId: string): Promise<Clinician[]> => {
  return axios
    .get(`/provider/organization/${clinicId}/provider/${providerId}/delegate`)
    .then(res => setOrganizationRoleFromClinic(res.data.results, clinicId));
};

export const addUserToClinicalTeam = async (
  clinicId: string,
  providerId: string,
  userId: string,
): Promise<Clinician[]> => {
  return axios
    .post(`/provider/organization/${clinicId}/provider/${providerId}/delegate`, { provider_id: userId })
    .then(res => res.data);
};

export const removeUserToClinicalTeam = async (
  clinicId: string,
  providerId: string,
  userId: string,
): Promise<string> => {
  return axios
    .delete(`/provider/organization/${clinicId}/provider/${providerId}/delegate/${userId}`)
    .then(res => res.data);
};

export const updateUserRoles = async (clinicId: string, providerId: string, roles: OrganizationRole[]) => {
  return axios
    .patch(`/provider/organization/${clinicId}/provider/${providerId}`, { provider_roles: roles })
    .then(res => res.data);
};

export const getPatientRequisitions = async (patientId: string): Promise<Requisition[]> => {
  return axios.get(`/provider/individual/${patientId}/requisition`).then(res => res.data.results);
};

export const downgradeRequisition = async (
  mainRequisitionId: string,
  requisitionIdsToDowngrade: string[],
): Promise<string> => {
  return axios
    .post(`/provider/requisition/${mainRequisitionId}/downgrade`, {
      support_requisition_ids: requisitionIdsToDowngrade,
    })
    .then(res => res.data);
};

export const fetchDraftOrders = async (): Promise<IDraftOrder[]> => {
  return axios.get('/draft-requisition').then(res => res.data);
};

export const createDraftOrder = async (payload: ICreateDraftOrderPayload): Promise<string> => {
  return axios.post('/draft-requisition', payload).then(res => res.data);
};
