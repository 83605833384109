import { DrugInfo, SourceInfo } from '@customTypes/drugs';

function checkGuidance(sourceInfo: SourceInfo | undefined) {
  if (sourceInfo && !sourceInfo.notActionable) {
    let alternateDrugAvailable = false;
    let dosingInformation = false;
    let otherPrescribingGuidance = false;
    sourceInfo.annotations.forEach(annotation => {
      if (!annotation.notActionable) {
        annotation.recommendationAnnotations.forEach(recommendation => {
          alternateDrugAvailable = alternateDrugAvailable || recommendation.alternateDrugAvailable;
          dosingInformation = dosingInformation || recommendation.dosingInformation;
          otherPrescribingGuidance = otherPrescribingGuidance || recommendation.otherPrescribingGuidance;
        });
      }
    });
    return [alternateDrugAvailable, dosingInformation, otherPrescribingGuidance];
  } else {
    return [false, false, false];
  }
}

export const hasGuidance = (drug: DrugInfo) => {
  if (!drug.notActionable) {
    const cpic = checkGuidance(drug.cpic);
    const dpwg = checkGuidance(drug.dpwg);
    const fda = checkGuidance(drug.fda);
    const fdaPgx = checkGuidance(drug.fdaPgx);
    return [
      cpic[0] || dpwg[0] || fda[0] || fdaPgx[0],
      cpic[1] || dpwg[1] || fda[1] || fdaPgx[1],
      cpic[2] || dpwg[2] || fda[2] || fdaPgx[2],
    ];
  } else {
    return [false, false, false];
  }
};
