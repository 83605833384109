import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { DatatableWrapper, TableBody, TableColumnType, TableHeader } from 'react-bs-datatable';
import { Button, Stack, Table } from 'react-bootstrap';

import InfoIconButton from '@components/Button/InfoIconButton';
import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import InfoDialog from '@components/Modals/InfoDialog';
import Placeholder from '@components/Placeholder/Placeholder';
import { IDraftOrder } from '@customTypes/draftOrder';
import { getClinicianName } from '@helpers/clinician';
import { fetchDraftOrders } from '@store/actions';

import NoDraftsContent from '../NoDraftsContent/NoDraftsContent';

export default function Drafts() {
  const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);
  const [drafts, setDrafts] = useState<IDraftOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);

      const res = await fetchDraftOrders();
      setDrafts([...res]);
    } catch (err) {
      console.error('Error on fetching draft orders: ', err);
    } finally {
      setLoading(false);
    }
  }, []);

  const headers: TableColumnType<IDraftOrder>[] = [
    {
      title: 'Name',
      prop: 'name',
      isSortable: true,
      alignment: { horizontal: 'left' },
      cell: row => (
        <a href='/' className='text-decoration-none'>
          {row.name}
        </a>
      ),
    },
    {
      title: 'Ordering Provider',
      prop: 'ordering_provider',
      cell: (row: IDraftOrder) => getClinicianName(row.ordering_provider),
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Created By',
      prop: 'created_by',
      cell: (row: IDraftOrder) => getClinicianName(row.created_by),
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Last Saved',
      prop: 'updated_at',
      cell: row => dayjs.utc(row.updated_at).format('MM/DD/YYYY'),
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Delete',
      prop: 'identifier',
      // classes below are important! Makes delete button align nicely with text in other cols
      cellProps: { className: 'align-middle py-0' },
      cell: row => (
        <Button size='sm' variant='ghost' className='delete-btn' aria-label={`Delete ${row.name}`}>
          <FontAwesomeIcon icon={faTrashCan} className='text-danger' fontSize={16} />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <ExpandableSection
        id='drafts'
        expandableContentId='draftsContent'
        headerTitle={
          <>
            <span>Drafts ({drafts.length})</span>
            <InfoIconButton onClick={() => setShowInfoDialog(true)} aria-label='Drafts Info Button' />
          </>
        }
      >
        {loading ? (
          <Table className='table-with-sticky-header' responsive>
            <thead>
              <tr>
                {headers.map(header => (
                  <th key={header.prop}>{header.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                  {headers.map(header => (
                    <td key={header.prop}>
                      <Placeholder size='sm' />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : drafts.length === 0 ? (
          <NoDraftsContent content={'You have no orders saved as drafts'} />
        ) : (
          <DatatableWrapper
            body={drafts}
            headers={headers}
            sortProps={{ initialState: { prop: 'updatedAt', order: 'desc' } }}
          >
            <Table className='table-with-sticky-header' responsive>
              <TableHeader />
              <TableBody />
            </Table>
          </DatatableWrapper>
        )}
      </ExpandableSection>
      {showInfoDialog && (
        <InfoDialog onClose={() => setShowInfoDialog(false)} title='Drafts'>
          <Stack gap={4}>
            <p className='mb-0'>
              When you edit a draft, you will arrive at the beginning of the order form. Your saved form entries will be
              revalidated against our current requirements. Some fields may no longer be valid or exist as our
              requirements evolve.
            </p>
            <p className='mb-0'>
              If the draft is submitted as an order, it will be moved into the Completed Drafts section.
            </p>
          </Stack>
        </InfoDialog>
      )}
    </>
  );
}
