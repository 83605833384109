import { useCallback, useContext, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import { Clinician } from '@customTypes/clinician';
import { ExtendedOrder, OrderStatus } from '@customTypes/order';
import { Requisition } from '@customTypes/requisition';
import { getClinicianName } from '@helpers/clinician';
import { isOrderReported } from '@helpers/order';
import { getPatientName } from '@helpers/patient';
import { getRequisitionStatus } from '@helpers/requisition';
import { fetchRequisitions } from '@store/actions';
import { Context } from '@store/store';

interface UseRequisitionResult {
  clinicians: Array<Clinician & { formattedName: string }>;
  patientName: string;
  requisitionId: string;
  requisitionDate: string;
  orders: ExtendedOrder[];
  requisitionStatus: string | null;
  isCanceled: boolean;
  isCompleted: boolean;
  isRequisitionsLoading: boolean;
  requisitionsLoadError: string | null;
  canDowngrade: boolean;
  disableDowngradeOrder: boolean;
  loadRequisitions: () => void;
}

const useRequisition = (requisition?: Requisition | null): UseRequisitionResult => {
  const [state, dispatch] = useContext(Context);

  const [loading, setLoading] = useState(state.requisitions === undefined);
  const [error, setError] = useState<string | null>(null);
  const patientName = useMemo(
    () => getPatientName(requisition?.subject?.name ?? requisition?.name),
    [requisition?.name, requisition?.subject],
  );
  const requisitionId = useMemo(() => requisition?.requisition_id ?? '', [requisition?.requisition_id]);
  const requisitionDate = useMemo(() => {
    if (!requisition?.submitted_at) {
      return '';
    }

    return dayjs.utc(requisition.submitted_at).format('MM/DD/YYYY');
  }, [requisition?.submitted_at]);

  const orders = useMemo(
    () =>
      (requisition?.orders || []).map(order => ({
        ...order,
        created_at: dayjs.utc(order.created_at).format('MM/DD/YYYY'),
        isReported: isOrderReported(order),
      })),
    [requisition?.orders],
  );

  /**
   * For now, we can downgrade if
   * there is at least one order with the product ids.
   * We will find a better way for that later.
   */
  const canDowngrade = useMemo(
    () =>
      !!requisition?.case_requisitions?.length &&
      orders.some(order => ['PR51025', 'PR51030', 'PR51058', 'PR51063'].includes(order.product_id)),
    [orders, requisition],
  );

  const requisitionStatus: string | null = useMemo(() => getRequisitionStatus(requisition), [requisition]);

  const isCanceled = useMemo(
    () => orders.every(order => [OrderStatus.CANCELED, OrderStatus.DENIED, OrderStatus.FAILED].includes(order.status)),
    [orders],
  );

  const isCompleted = useMemo(() => orders.every(isOrderReported), [orders]);

  const disableDowngradeOrder = useMemo(() => {
    // Disable if a main order status is Complete or Canceled
    if (isCanceled || isCompleted) {
      return true;
    }
    // Disable if both main order and related orders status are Analyzing (sampple is accessioned)
    if (requisition?.case_requisitions?.length) {
      return requisition.case_requisitions.every(requisition => !!requisition.sample_received_at);
    }

    return false;
  }, [isCanceled, isCompleted, requisition]);

  const clinicians = useMemo(
    () =>
      (requisition?.clinicians || []).map(clinician => ({
        ...clinician,
        formattedName: getClinicianName(clinician),
      })),
    [requisition],
  );

  const loadRequisitions = useCallback(() => {
    /**
     * Do not refetch requisitions if data already exist
     */
    setLoading(true);
    setError(null);

    fetchRequisitions()
      .then(requisitions => {
        dispatch({ type: 'SET_REQUISITIONS', payload: requisitions });
      })
      .catch(err => {
        setError(err?.toString() ?? 'Server Error');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clinicians,
    patientName,
    requisitionId,
    requisitionDate,
    orders,
    requisitionStatus,
    isCanceled,
    isCompleted,
    isRequisitionsLoading: loading,
    requisitionsLoadError: error,
    canDowngrade,
    disableDowngradeOrder,
    loadRequisitions,
  };
};

export default useRequisition;
