import { lazy, useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import OrderFormContainer from '@components/RequisitionCreate/OrderFormContainer/OrderFormContainer';
import { ORDERS_AND_REPORTS } from '@constants/routes';
import { Campaign } from '@customTypes/campaign';
import useAuth from '@hooks/useAuth';

const RequisitionJSONSchemaForm = lazy(
  () => import('@components/RequisitionCreate/RequisitionJSONSchemaForm/RequisitionJSONSchemaForm'),
);
const OrderingOptionSelect = lazy(() => import('./components/OrderingOptionSelect/OrderingOptionSelect'));

export default function RequisitionCreate() {
  const navigate = useNavigate();
  const { hasPartnershipOrdering } = useAuth();

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null | undefined>(undefined);
  const isOnCampaignSelectScreen = useMemo(
    () => selectedCampaign === undefined && hasPartnershipOrdering,
    [selectedCampaign, hasPartnershipOrdering],
  );

  const moveToRequisitions = useCallback(() => {
    navigate(ORDERS_AND_REPORTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOrderingOptionSelect = (campaign: Campaign | null) => {
    setSelectedCampaign(campaign);
  };

  const breadcrumbs = useMemo(
    () => [
      {
        onClick: moveToRequisitions,
        children: 'Orders and Reports',
      },
      {
        active: true,
        children: 'New Order',
      },
    ],
    [moveToRequisitions],
  );

  if (isOnCampaignSelectScreen) {
    return (
      <OrderFormContainer
        breadcrumbs={breadcrumbs}
        title='Your ordering options'
        subtitle='Select an option to proceed.'
        onCancel={moveToRequisitions}
      >
        <OrderingOptionSelect onSelect={onOrderingOptionSelect} />
      </OrderFormContainer>
    );
  }

  return (
    <OrderFormContainer breadcrumbs={breadcrumbs} title='New Order' onCancel={moveToRequisitions}>
      <RequisitionJSONSchemaForm selectedCampaignCode={selectedCampaign?.campaign_code} />
    </OrderFormContainer>
  );
}
