import { IUsername } from '@customTypes/user';

export const getPatientName = (name?: IUsername): string => {
  if (!name) return '';

  return name.first + ' ' + name.last;
};

/**
 * We might need to find a better solution to extract a order test name
 *
 * @param originName product name
 * @returns
 */
export const getPatientOrderTestName = (
  originName: string,
): { brand?: string; productLine?: string; productName?: string } => {
  const result: { brand?: string; productLine?: string; productName?: string } = {};
  let str: string = originName;

  // Extract MyOme if exists
  if (str.toLowerCase().startsWith('myome')) {
    result.brand = 'MyOme';
    str = str.slice(6);
  }
  // Find a first index of comma so that it can be separated into two lines
  const firstCommaIndex = str.indexOf(', ');

  if (firstCommaIndex > -1) {
    result.productLine = str.slice(0, firstCommaIndex)?.trim();
    result.productName = str.slice(firstCommaIndex + 2)?.trim();
  } else {
    result.productLine = str.trim();
  }

  if (result.productLine && result.productLine.toLowerCase().includes('research use only')) {
    const firstDashIndex = str.indexOf('-');
    str = result.productLine;

    result.productLine = str.slice(0, firstDashIndex)?.trim();
    result.productName = str.slice(firstDashIndex + 2)?.trim();
  }

  return result;
};
