import { useCallback, useContext, useEffect, useState } from 'react';

import { Clinician, OrganizationRole } from '@customTypes/clinician';
import useAuth from '@hooks/useAuth';
import { fetchOrganizationProviders, fetchClinicalTeamByProvider } from '@store/actions';
import { Context } from '@store/store';

interface IUseUsers {
  fetchUsers: (fetchClinicalTeam?: boolean) => Promise<void>;
  clinicians: Clinician[];
}

export default function useUsers(): IUseUsers {
  const [state, dispatch] = useContext(Context);
  const { isOrderingProvider } = useAuth();

  const [clinicians, setClinicians] = useState<Clinician[]>([]);

  const fetchUsers = useCallback(
    async (fetchClinicalTeam = false) => {
      if (state.clinicId && state.currentClinician) {
        fetchOrganizationProviders(state.clinicId).then(users => dispatch({ type: 'SET_USERS', payload: users }));

        if (isOrderingProvider && fetchClinicalTeam) {
          fetchClinicalTeamByProvider(state.clinicId, state.currentClinician.clinician_id!).then(clinicalTeam =>
            dispatch({ type: 'SET_CLINICAL_TEAM', payload: clinicalTeam }),
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.clinicId, state.currentClinician, isOrderingProvider],
  );

  useEffect(() => {
    setClinicians(state.users.filter(user => (user.organizational_roles || []).includes(OrganizationRole.CLINICIAN)));
  }, [state.users]);

  return {
    fetchUsers,
    clinicians,
  };
}
