import { forwardRef } from 'react';

import RbButton, { ButtonProps as RbButtonProps } from 'react-bootstrap/Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IconButtonProps extends RbButtonProps {
  startIcon?: IconProp;
  endIcon?: IconProp;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, startIcon, endIcon, className = '', ...buttonProps }, ref) => {
    return (
      <RbButton ref={ref} className={`d-flex align-items-center ${className}`} {...buttonProps}>
        {startIcon ? <FontAwesomeIcon icon={startIcon} fontSize={16} className='me-2' /> : null}
        {children}
        {endIcon ? <FontAwesomeIcon icon={endIcon} fontSize={16} className='ms-2' /> : null}
      </RbButton>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
