import { MouseEvent, useState } from 'react';

import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@components/Button/IconButton';
import { Requisition } from '@customTypes/requisition';
import useRequisition from '@hooks/useRequisition';
import useViewportSizing from '@hooks/useViewportSizing';

import OrderReceiptDialog from './OrderReceiptDialog';
import DowngradeOrderDialog from './DowngradeOrderDialog';

/**
 * In future we may want to change name of this component.
 * Test (UI) = Order (API)
 */
import PatientDetailOrderCard from './PatientDetailOrderCard';
import RelatedOrders from './RelatedOrders';

interface IProps {
  requisition: Requisition;
}

export default function PatientDetailOrder({ requisition }: IProps) {
  const { isMobile } = useViewportSizing();
  const { canDowngrade, disableDowngradeOrder, orders } = useRequisition(requisition);

  const [showDowngradeOrder, setShowDowngradeOrder] = useState(false);
  const [showOrderReceipt, setShowOrderReceipt] = useState(false);

  const onOrderReceipt = (evt: MouseEvent) => {
    evt.preventDefault();

    setShowOrderReceipt(true);
  };

  const onDowngradeOrder = () => {
    setShowDowngradeOrder(true);
  };

  return (
    <div className='bg-light rounded-3 p-2 d-flex flex-column gap-2 patient-detail-order'>
      <div className='d-flex flex-column align-items-start order-header'>
        <p className='fs-body small fw-bold text-second mb-0'>Order ID:</p>
        <div className='d-flex flex-column flex-sm-row align-items-sm-center gap-2 gap-sm-3'>
          <p className='mb-0 fs-5 lh-xs fw-bold align-self-start align-self-sm-center'>{requisition.requisition_id}</p>

          <div className='d-flex gap-2'>
            <IconButton endIcon={faEllipsis} variant='outline-dark' size='sm' onClick={onOrderReceipt}>
              {isMobile ? 'Receipt' : 'Order Receipt'}
            </IconButton>
            {canDowngrade && (
              <IconButton
                endIcon={faEllipsis}
                variant='outline-dark'
                disabled={disableDowngradeOrder}
                size='sm'
                onClick={onDowngradeOrder}
              >
                {isMobile ? 'Downgrade' : 'Downgrade Order'}
              </IconButton>
            )}
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-wrap flex-md-row gap-4 order-cards'>
        {orders.map(order => (
          <PatientDetailOrderCard key={order.product_id} order={order} requisitionId={requisition.requisition_id} />
        ))}
      </div>

      {!!requisition.case_requisitions?.length && <RelatedOrders requisitions={requisition.case_requisitions!} />}

      {showOrderReceipt && <OrderReceiptDialog requisition={requisition} onClose={() => setShowOrderReceipt(false)} />}
      {showDowngradeOrder && (
        <DowngradeOrderDialog requisition={requisition} onClose={() => setShowDowngradeOrder(false)} />
      )}
    </div>
  );
}
