import { Fragment, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFamilyPants, faUser } from '@fortawesome/pro-solid-svg-icons';
import { Form, ListGroup } from 'react-bootstrap';

import { Requisition } from '@customTypes/requisition';
import { getRequisitionName } from '@helpers/requisition';

interface IProps {
  requisition: Requisition;
  selectedRelatedOrderIds: string[];
  setSelectedRelatedOrderIds: React.Dispatch<React.SetStateAction<string[]>>;
  isPatient?: boolean;
}

export default function DowngradeOrderList({
  isPatient = false,
  selectedRelatedOrderIds,
  setSelectedRelatedOrderIds,
  requisition,
}: IProps) {
  const listGroupTitle = useMemo(() => (isPatient ? 'Patient’s Order' : 'Related Orders'), [isPatient]);

  const onSelectionChange = (evt: React.ChangeEvent<HTMLInputElement>, requisitionId: string) => {
    if (evt.target.checked) {
      setSelectedRelatedOrderIds(selected => selected.concat(requisitionId));
    } else {
      setSelectedRelatedOrderIds(selected => selected.filter(item => item !== requisitionId));
    }
  };

  return (
    <ListGroup className='mb-4'>
      <ListGroup.Item className='bg-primary text-white'>
        <FontAwesomeIcon icon={(isPatient ? faUser : faFamilyPants) as IconDefinition} fontSize={16} />
        <span className='d-inline-block ms-2'>{listGroupTitle}</span>
      </ListGroup.Item>
      {isPatient && (
        <ListGroup.Item>
          <dl className='mb-0'>
            <dt className='visually-hidden'>Name</dt>
            <dd className='h6 fw-bold mb-0'>{getRequisitionName(requisition)}</dd>
            <dt className='visually-hidden'>Order Id</dt>
            <dd className='fs-sm mb-0'>{requisition.requisition_id}</dd>
            <dt className='fw-bold fs-xs mb-0'>Products ordered:</dt>
            <dd className='mb-0'>
              <ul className='fs-xs mb-0'>
                {requisition.orders.map((o, index) => (
                  <li key={o + '_' + index}>{o.product_name}</li>
                ))}
              </ul>
            </dd>
          </dl>
        </ListGroup.Item>
      )}
      {!isPatient && (requisition.case_requisitions || []).length > 0 && (
        <Fragment>
          {requisition.case_requisitions!.map((related, index) => {
            const accessioned = !!related.sample_received_at;

            return (
              <ListGroup.Item key={related + '_' + index} className={accessioned ? 'bg-body-secondary' : ''}>
                <div className='d-flex'>
                  <div className='me-2'>
                    <Form.Check
                      aria-label='select order'
                      checked={selectedRelatedOrderIds.includes(related.requisition_id)}
                      disabled={accessioned}
                      onChange={evt => onSelectionChange(evt, related.requisition_id)}
                    />
                  </div>
                  <div>
                    <dl className='mb-0'>
                      <dt className='visually-hidden'>Name</dt>
                      <dd className='h6 fw-bold mb-0'>{getRequisitionName(related)}</dd>
                      <dt className='visually-hidden'>Order Id</dt>
                      <dd className='fs-sm mb-0'>{related.requisition_id}</dd>
                      <dt className='fw-bold fs-xs mb-0'>Products ordered:</dt>
                      <dd className='mb-0'>
                        <ul className='fs-xs mb-0'>
                          {related.orders.map((o, index) => (
                            <li key={o + '_' + index}>{o.product_name}</li>
                          ))}
                        </ul>
                      </dd>
                    </dl>
                    {accessioned && (
                      <p className='fw-bold fs-xs mt-2 mb-0'>
                        Order cannot be canceled, the sample has already been accessioned.
                      </p>
                    )}
                  </div>
                </div>
              </ListGroup.Item>
            );
          })}
        </Fragment>
      )}
    </ListGroup>
  );
}
