import { useMemo, useState } from 'react';

import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import CommonDialog from '@components/Modals/CommonDialog';
import useToast from '@contexts/useToast';
import { Requisition } from '@customTypes/requisition';
import { ToastType } from '@customTypes/toast';
import useRequisition from '@hooks/useRequisition';
import { downgradeRequisition } from '@store/actions';

import DowngradeOrderList from './DowngradeOrderList';
import usePatientDetail from '@hooks/usePatientDetail';

interface IProps {
  requisition: Requisition;
  onClose: () => void;
}

export default function DowngradeOrderDialog({ requisition, onClose }: IProps) {
  const params = useParams();
  const patientId = useMemo(() => params?.id, [params]);
  const { requisitionId } = useRequisition(requisition);
  const { loadPatientDetailData } = usePatientDetail(patientId);
  const { addToast } = useToast();

  const [selectedRelatedOrderIds, setSelectedRelatedOrderIds] = useState<string[]>([]);
  const [confirmed, setConfirmed] = useState(false);
  const [processing, setProcessing] = useState(false);

  const onDowngrade = async () => {
    try {
      setProcessing(true);

      await downgradeRequisition(requisitionId, selectedRelatedOrderIds);
      addToast({
        // eslint-disable-next-line max-len
        headerTitle: `${requisitionId} has been downgraded. Related order ${selectedRelatedOrderIds.join(', ')} has been canceled.`,
        type: ToastType.DEFAULT,
      });
      loadPatientDetailData();
      onClose();
    } catch (err) {
      console.error('Error on requisition downgrade: ', err);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <CommonDialog
      onClose={onClose}
      onConfirm={onDowngrade}
      title='Downgrade Order'
      modalProps={{
        'data-testid': 'downgradeOrderDialog',
        'aria-labelledby': `downgrade-order_${requisitionId}`,
        id: 'downgrade-order-dialog',
        size: 'lg',
        scrollable: true,
      }}
      cancelBtnProps={{
        'data-testid': 'closeDowngradeOrderDialog',
        children: 'Close',
      }}
      confirmBtnProps={{
        children: 'Submit',
        'data-testid': 'submitDowngradeOrder',
        disabled: !confirmed || !selectedRelatedOrderIds.length || processing,
      }}
      titleProps={{
        id: `downgrade-order_${requisitionId}`,
      }}
    >
      <p className='fs-sm'>
        Downgrade your order by removing family members (e.g. Related Orders) from testing. Removing a family member
        from testing will cancel their order. Family members with an accessioned sample cannot be removed.
      </p>
      <p className='fw-bold fs-sm'>
        Orders will be automatically canceled if the sample is not received within 3 weeks of submission.
      </p>
      <DowngradeOrderList
        requisition={requisition}
        selectedRelatedOrderIds={selectedRelatedOrderIds}
        setSelectedRelatedOrderIds={setSelectedRelatedOrderIds}
        isPatient
      />
      <DowngradeOrderList
        requisition={requisition}
        selectedRelatedOrderIds={selectedRelatedOrderIds}
        setSelectedRelatedOrderIds={setSelectedRelatedOrderIds}
      />
      <Form.Group as='fieldset' className='border border-aqua rounded p-3'>
        <legend className='legend-label'>Provider confirmation:</legend>
        <Form.Check
          id='confirmCheck'
          label='I confirm that I would like to cancel the selected orders above and downgrade
            the proband’s order to include fewer family members. I have received consent from the
            patients and/or guardians.'
          aria-describedby='confirmCheckDesc'
          className='fs-sm'
          checked={confirmed}
          onChange={() => setConfirmed(confirmed => !confirmed)}
        />
        <Form.Text id='confirmCheckDesc' muted>
          Required.
        </Form.Text>
      </Form.Group>
    </CommonDialog>
  );
}
