import { lazy, ReactNode, Suspense, useContext, useState } from 'react';

import { Breadcrumb, BreadcrumbItemProps, Card, Stack } from 'react-bootstrap';
import { faArrowLeftLong, faCopy } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@components/Button/IconButton';
import CancelConfirmDialog from '@components/RequisitionCreate/CancelConfirmDialog/CancelConfirmDialog';
import useAuth from '@hooks/useAuth';
import { Context } from '@store/store';

import './OrderFormContainer.scss';

interface IProps {
  breadcrumbs: BreadcrumbItemProps[];
  title: string;
  subtitle?: string;
  children: ReactNode;
  onCancel: () => void;
  isOrderNewReport?: boolean;
}

const LazySaveDraftOrderDialog = lazy(() => import('../SaveDraftOrderDialog/SaveDraftOrderDialog'));

const DEFAULT_SUBTITLE =
  // eslint-disable-next-line max-len
  'Fill out the form in each step as provided. Use the Next button to continue to the next step, and the Submit button when all steps are completed. Some product selections may not require additional information.';

export default function OrderFormContainer({
  breadcrumbs,
  children,
  title,
  subtitle,
  onCancel,
  isOrderNewReport = false,
}: IProps) {
  const [state] = useContext(Context);
  const { isInternalUser } = useAuth();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDraftOrderDialog, setShowDraftOrderDialog] = useState<boolean>(false);

  return (
    <Suspense>
      <Stack gap={4} className='layout-single-col-py order-form-container' data-testid='newOrderPage'>
        {breadcrumbs.length > 0 && (
          <Breadcrumb listProps={{ className: 'mb-0' }}>
            {breadcrumbs.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index} {...breadcrumb} />
            ))}
          </Breadcrumb>
        )}
        <Card as='section' className='text-start px-4 pb-4 pt-2 box-shadow-50 position-static'>
          <Card.Title as='h1' className='mt-md-1 fw-bold page-title'>
            {title}
          </Card.Title>
          <Card.Subtitle className='mt-md-1 fs-body fw-normal text-second mb-0 page-subtitle' as='p'>
            {subtitle || DEFAULT_SUBTITLE}
          </Card.Subtitle>
          <Card.Body className='p-0 pt-4'>{children}</Card.Body>
          <Card.Footer className='d-flex flex-column flex-sm-row gap-4 gap-sm-3 bg-white p-0 pt-4 mt-3 border-top'>
            <IconButton
              variant='outline-dark'
              className='justify-content-center justify-content-sm-start'
              startIcon={faArrowLeftLong}
              onClick={() => setShowConfirmDialog(true)}
              data-testid='cancelAndReturnBtn'
            >
              Cancel and return
            </IconButton>
            {!isOrderNewReport && isInternalUser && state.requisitionTaskForm?.formdata?.clinicians?.length && (
              <IconButton
                variant='outline-dark'
                className='justify-content-center justify-content-sm-start'
                startIcon={faCopy}
                onClick={() => setShowDraftOrderDialog(true)}
              >
                Save as Draft
              </IconButton>
            )}
          </Card.Footer>
        </Card>
      </Stack>

      {showConfirmDialog && (
        <CancelConfirmDialog
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onCancel}
          isOrderNewReport={isOrderNewReport}
        />
      )}
      {showDraftOrderDialog && <LazySaveDraftOrderDialog onClose={() => setShowDraftOrderDialog(false)} />}
    </Suspense>
  );
}
