import { ToastProps } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';

export enum ToastType {
  BASIC,
  CUSTOM_ACTIONS,
  ACTION_BLOCKED,
  DEFAULT,
}

export interface ToastItem extends ToastProps {
  id: string;
  variant?: Variant;
  type?: ToastType;
  content?: string;
  headerTitle?: string;
  errorString?: string;
}
