interface IProps {
  content: string;
}

export default function NoDetailContent({ content }: IProps) {
  return (
    <div className='bg-light w-100 rounded-1 no-patient-detail-content'>
      <p className='text-center fw-medium fs-5 lh-xs mb-0'>{content}</p>
    </div>
  );
}
