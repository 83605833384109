import { Stack } from 'react-bootstrap';
import Drafts from './components/Drafts/Drafts';
import CompletedDrafts from './components/CompletedDrafts/CompletedDrafts';
import DeletedDrafts from './components/DeletedDrafts/DeletedDrafts';

import './DraftOrders.scss';

export default function DraftOrders() {
  return (
    <Stack gap={4} className='layout-single-col-py draft-orders-container'>
      <Stack as='section' className='text-start page-title-section'>
        <h1 className='mb-0 fw-bold page-title'>Your Draft Orders</h1>
        <div className='fs-body fw-normal text-second page-subtitle'>
          <p className='mb-1'>
            Draft orders can be seen by the ordering provider, organization default delegates, and clinical team
            delegates for the ordering provider.
          </p>
        </div>
      </Stack>
      <Drafts />
      <CompletedDrafts numOfCompletedDrafts={0} />
      <DeletedDrafts numOfDeletedDrafts={0} />
    </Stack>
  );
}
