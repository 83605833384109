import { lazy, useCallback, useMemo } from 'react';

import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import OrderFormContainer from '@components/RequisitionCreate/OrderFormContainer/OrderFormContainer';
import usePatientDetail from '@hooks/usePatientDetail';

const RequisitionJSONSchemaForm = lazy(
  () => import('@components/RequisitionCreate/RequisitionJSONSchemaForm/RequisitionJSONSchemaForm'),
);

export default function OrderNewReport() {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const patientId = useMemo(() => params?.id, [params]);
  const requisitionId = useMemo(() => params?.reqId, [params]);
  const campaignCode = useMemo(() => searchParams.get('campaign'), [searchParams]) ?? undefined;

  const { patientName, loading } = usePatientDetail(patientId);

  const moveToPatientDetail = useCallback(() => {
    navigate(`/patients/${patientId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const breadcrumbs = useMemo(
    () => [
      {
        onClick: moveToPatientDetail,
        children: patientName,
      },
      {
        active: true,
        children: 'Order New Report',
      },
    ],
    [moveToPatientDetail, patientName],
  );

  if (loading) {
    return null;
  }

  return (
    <OrderFormContainer
      isOrderNewReport
      breadcrumbs={breadcrumbs}
      title='Order New Report'
      onCancel={moveToPatientDetail}
    >
      <RequisitionJSONSchemaForm selectedCampaignCode={campaignCode} selectedRequisitionId={requisitionId} />
    </OrderFormContainer>
  );
}
