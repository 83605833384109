import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { orderBy } from 'lodash';

import { IPatientDetailRes, PatientRequisitionDetails } from '@customTypes/patient';
import { Requisition } from '@customTypes/requisition';
import { getPatientName } from '@helpers/patient';
import { isOrderReported } from '@helpers/order';
import { getIndividualDetail } from '@store/actions';
import { Context } from '@store/store';

interface IUsePatientDetail {
  requisitions: Requisition[];
  firstCompletedRequisition?: Requisition;
  patientDetailInfo: IPatientDetailRes | null;
  hasGuidance: boolean;
  patientName: string;
  loading: boolean;
  loadPatientDetailData: () => Promise<void>;
}

export default function usePatientDetail(patientId?: string): IUsePatientDetail {
  const [state, dispatch] = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [patientDetailInfo, hasGuidance, requisitions, firstCompletedRequisition] = useMemo(() => {
    let info = null;
    let guidance = false;
    const reqs: Requisition[] = [];
    let firstCompletedReq: Requisition | undefined;
    if (patientId!) {
      const details: PatientRequisitionDetails | null = state.patientsMap?.[patientId];
      if (details) {
        info = details.individual;
        guidance = details.has_pgx_guidelines;
        if (details.requisitions && details.requisitions.length > 0) {
          reqs.push(...details.requisitions);
          const completedRequisitions: Requisition[] = reqs
            .filter(req => req.orders.every(isOrderReported))
            .map(req => ({
              ...req,
              completedAt: orderBy(req.orders, ['created_at'], ['desc'])[0].created_at,
            }));
          firstCompletedReq = orderBy(completedRequisitions, ['completedAt'], ['asc'])?.[0];
        }
      }
    }
    return [info, guidance, reqs, firstCompletedReq];
  }, [patientId, state.patientsMap]);

  const patientName = useMemo(() => getPatientName(patientDetailInfo?.name), [patientDetailInfo]);

  const loadData = useCallback(async () => {
    if (patientId) {
      try {
        setLoading(true);

        const individualDetail = await getIndividualDetail(patientId);
        dispatch({ type: 'SET_PATIENT_DETAIL', payload: { patientId, result: individualDetail } });
      } catch (e) {
        console.error('Error on patient detail api: ', e);
      } finally {
        setLoading(false);
      }
    }
  }, [patientId, dispatch]);

  useEffect(() => {
    if (!patientDetailInfo) {
      loadData();
    }
  }, [loadData, patientDetailInfo]);

  return {
    requisitions,
    firstCompletedRequisition,
    patientDetailInfo,
    hasGuidance,
    patientName,
    loadPatientDetailData: loadData,
    loading,
  };
}
