import * as Sentry from '@sentry/react';
import { FC, useMemo, useState } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPrescription } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Stack, Table } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import drugNames from '@assets/drug_generic_to_trademarks.json';
import { DrugInfo, Guidance } from '@customTypes/drugs';
import PGxGuidanceDialog from './PGxGuidanceDialog';

interface TradesProps {
  trades: string[];
}
const Trades: FC<TradesProps> = ({ trades }) => {
  const [showAll, setShowAll] = useState(false);
  const callback = () => {
    if (showAll) {
      setShowAll(false);
    } else {
      setShowAll(true);
    }
  };
  if (trades.length === 0) {
    return (
      <td>
        <span className='text-body-secondary'>Not Applicable</span>
      </td>
    );
  }
  const btnTxt = showAll ? 'less' : `... ${trades.length - 10} more`;
  return (
    <td>
      {trades.map((tradeName: string, index: number) => {
        return (
          <span key={tradeName} className={!showAll && index > 9 ? 'trade-name sr-only' : 'trade-name'}>
            {tradeName}
          </span>
        );
      })}
      {trades.length > 10 && (
        <Button variant='link' className='fs-xs fw-medium p-1 d-inline' onClick={callback}>
          {btnTxt}
        </Button>
      )}
    </td>
  );
};

interface PGxGuidanceProps {
  guidance: Guidance;
}
export default function PGxGuidance({ guidance }: PGxGuidanceProps) {
  const [drugSelection, setDrugSelection] = useState<string[]>([]);
  const [meds, allDrugs] = useMemo(() => {
    const guidanceMeds = Object.keys(guidance.pharmgkb.drugs)
      .map((key: string) => {
        return guidance.pharmgkb.drugs[key];
      })
      .sort((drugA: DrugInfo, drugB: DrugInfo) => {
        if (drugA.notActionable === drugB.notActionable) {
          return 0;
        }
        if (!drugA.notActionable && drugB) {
          return -1;
        }
        return 1;
      });
    const getBrandNames = (genericDrugName: string) => {
      if (genericDrugName in drugNames) {
        return drugNames[genericDrugName as keyof typeof drugNames];
      } else {
        const logMessage = `${genericDrugName} not in drug-Brand Name mapping`;
        Sentry.captureMessage(logMessage);
        return [];
      }
    };
    const drugs = Object.keys(guidance.pharmgkb.drugs)
      // @ts-ignore
      .flatMap((drug: string) => [drug, ...getBrandNames(drug)])
      .sort();
    return [guidanceMeds, drugs];
  }, [guidance.pharmgkb.drugs]);

  // when top level notActionable === true
  const noGuidance = <span className='fs-sm text-body-secondary'>None</span>;

  const pgxTableFootnote = (
    <>
      <sup>&dagger;</sup>Medications that are not listed have no known PGx guidance.
    </>
  );
  const pgxDisclaimer = (
    <div className='fs-xs text-body-secondary text-start'>
      <p className='mb-2'>{pgxTableFootnote}</p>
      <p className='mb-0'>
        Pharmacogenomic guidance is provided based on PharmGKB resources, which compile materials from various sources
        of clinical pharmacogenomic information. Providers may use these guidelines as a reference, along with other
        clinical factors, to help them when selecting medications and dosages for this patient.
      </p>
      <p className='mb-0'>
        This guideline information is not intended to be used in isolation, and the provider needs to take into account
        all clinical considerations and FDA prescribing information before making any changes to treatment. Commonly
        used trade names for medications are listed for reference only.
      </p>
      <p className='mb-0'>
        This report may not include all trade names or all pharmaceutical products available, and inclusion does not
        indicate any preference or recommendation by MyOme. Guideline information published by professional associations
        (e.g. CPIC, FDA, DPWG) does not take into account other genetic variants and environmental or social factors
        that may affect patient response.
      </p>
      <p className='mb-2'>
        Other factors not included in this report include, but are not limited to, environmental factors (e.g.,smoking),
        health factors (e.g., diet), social and familial factors, various medical conditions, and drug-to-drug
        interactions. Administration of any medication, including the ones listed in this tool, requires careful
        therapeutic monitoring regardless of the phenotype or genotype-predicted interaction reported.
      </p>
      <p>
        MyOme is in no way affiliated with or endorsed by{' '}
        <a href='https://www.pharmgkb.org/' target='_blank' rel='nofollow noreferrer'>
          PharmGKB
        </a>{' '}
        and provides their data in accordance with{' '}
        <a href='https://www.pharmgkb.org/page/dataUsagePolicy' target='_blank' rel='nofollow noreferrer'>
          PharmGKB&apos;s Data Usage Policy
        </a>
        &#46;
      </p>
    </div>
  );

  return (
    <>
      <Stack direction='horizontal' gap={3} className='pgx-filter-wrapper py-2 ps-3 pe-3 pe-sm-5 text-start'>
        <div className='py-1 align-self-stretch'>
          <FontAwesomeIcon icon={faPrescription as IconDefinition} fontSize={24} className='text-primary' />
        </div>
        <Form.Group className='w-100'>
          <Form.Label htmlFor='pgxTableFilter'>Filter medications by name</Form.Label>
          <Typeahead
            onChange={selected => setDrugSelection(selected as string[])}
            clearButton
            id='pgxTableFilter'
            options={allDrugs}
            placeholder='Begin typing...'
            selected={drugSelection}
          />
          <Form.Text className='fs-sm'>Medication name can be generic or trademark.</Form.Text>
        </Form.Group>
      </Stack>

      <div className='guidance-table-wrapper'>
        <Table bordered size='sm' className='text-start guidance-table'>
          <thead>
            <tr>
              <th colSpan={2}>Medication Name</th>
              <th rowSpan={2} className='align-top th-guidance' style={{ width: 190 }}>
                Actionable Guidance<sup>&dagger;</sup>
              </th>
            </tr>
            <tr className='spanned-row'>
              <th style={{ width: '15%' }}>Generic</th>
              <th>Trademark</th>
            </tr>
          </thead>
          <tbody>
            {meds
              .filter(med => {
                if (drugSelection.length === 0) {
                  return true;
                }
                return (
                  med.drug.name === drugSelection[0] ||
                  (drugNames as { [index: string]: string[] })[med.drug.name]?.some(d => d === drugSelection[0])
                );
              })
              .map(row => {
                const trades = (drugNames as { [index: string]: string[] })[row.drug.name] || [];
                return (
                  <tr key={row.drug.id}>
                    <th scope='row'>
                      <span className='fst-italic'>{row.drug.name}</span>
                    </th>
                    <Trades trades={trades} />
                    <td className='td-guidance'>
                      {row.notActionable === true ? (
                        noGuidance
                      ) : (
                        <PGxGuidanceDialog drugInfo={row} tradeNames={trades} />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      {pgxDisclaimer}
    </>
  );
}
