import { FC, useState } from 'react';

import { Badge, Button, Modal, Stack } from 'react-bootstrap';

import PGxGuidanceAnnotation from './PGxGuidanceAnnotation';
import PGxGuidanceBadge from './PGxGuidanceBadge';

import { Annotation, DrugInfo, SourceInfo } from '@customTypes/drugs';
import { CPIC_FULL, DPWG_FULL, FDA_FULL, FDAPGX_FULL } from '@constants/pgxguidance';
import { useDrug } from '@hooks/useDrug';

interface GuidanceSectionProps {
  fullTitle: string;
  abbreviatedTitle: string;
  sourceInfo: SourceInfo;
}
const GuidanceSection: FC<GuidanceSectionProps> = ({ fullTitle, abbreviatedTitle, sourceInfo }) => {
  // for handling the weird case where the sourceInfo appears to be actionable, but none of the annotations agree
  const actionableWithoutRecommendation =
    !sourceInfo.notActionable && sourceInfo.annotations.every(a => a.notActionable);
  return (
    <section>
      <h4 className='h5 fw-medium d-flex align-items-center'>
        <abbr title={fullTitle} className='me-2'>
          {abbreviatedTitle}
        </abbr>{' '}
        Annotations
        <MultipopulationBadge annotations={sourceInfo.annotations} />
      </h4>
      {sourceInfo.notActionable ? (
        <p className='mb-4'>No Guidance.</p>
      ) : (
        <>
          {sourceInfo.annotations.map(annotation => (
            <PGxGuidanceAnnotation
              annotation={annotation}
              key={annotation.id}
              actionableWithoutRecommendation={actionableWithoutRecommendation}
            />
          ))}
        </>
      )}
    </section>
  );
};

interface MultipopulationBadgeProps {
  annotations: Annotation[];
}

const MultipopulationBadge: FC<MultipopulationBadgeProps> = ({ annotations }: MultipopulationBadgeProps) => {
  const showBadge = annotations.some((annotation: Annotation) => annotation.multipopulation);
  if (!showBadge) {
    return null;
  }
  return (
    <span className='fs-sm'>
      <Badge bg='primary-subtle' text='primary-emphasis' className='ms-2'>
        <span className='text-uppercase'>Multipop</span>
      </Badge>
    </span>
  );
};

interface PGxGuidanceDialogProps {
  drugInfo: DrugInfo;
  tradeNames: string[];
}

export default function PGxGuidanceDialog({ drugInfo, tradeNames }: PGxGuidanceDialogProps) {
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const modalId = drugInfo.drug.id + '_dialog';
  const modalTitleId = drugInfo.drug.id + '_title';
  const modalTitle = 'Guidance for ' + drugInfo.drug.name.charAt(0).toUpperCase() + drugInfo.drug.name.slice(1);

  const [alternateDrugAvailable, dosingInformation, otherPrescribingGuidance] = useDrug(drugInfo);

  return (
    <>
      <div>
        <Button
          variant='ghost-primary'
          size='sm'
          aria-label='Actionable guidance modal'
          className='guidance-btn mb-1'
          onClick={handleShow}
        >
          View
        </Button>
        <Stack direction='horizontal' gap={1} className='justify-content-center'>
          {dosingInformation && <PGxGuidanceBadge guidanceType='dosingInformation' />}
          {alternateDrugAvailable && <PGxGuidanceBadge guidanceType='alternateDrugAvailable' />}
          {otherPrescribingGuidance && <PGxGuidanceBadge guidanceType='otherPrescribingGuidance' />}
        </Stack>
      </div>
      <Modal scrollable size='xl' show={show} onHide={handleClose} id={modalId} aria-labelledby={modalTitleId}>
        <Modal.Header closeButton>
          <Modal.Title as='h4' id={modalTitleId} className='fs-5 fw-medium lh-sm'>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {drugInfo.cpic && (
            <GuidanceSection fullTitle={CPIC_FULL} abbreviatedTitle='CPIC' sourceInfo={drugInfo.cpic} />
          )}
          {drugInfo.dpwg && (
            <GuidanceSection fullTitle={DPWG_FULL} abbreviatedTitle='DPWG' sourceInfo={drugInfo.dpwg} />
          )}
          {drugInfo.fda && <GuidanceSection fullTitle={FDA_FULL} abbreviatedTitle='FDA' sourceInfo={drugInfo.fda} />}
          {drugInfo.fdaPgx && (
            <GuidanceSection fullTitle={FDAPGX_FULL} abbreviatedTitle='FDA PGx' sourceInfo={drugInfo.fdaPgx} />
          )}
          <section className='p-2 bg-light border rounded rounded-1'>
            <h4 className='fs-6 fw-medium'>Trade names for {drugInfo.drug.name}</h4>
            <ul className='fs-sm list-style-none ps-2 mb-2'>
              {tradeNames.length > 0 ? (
                tradeNames.map((t, index) => (
                  <li key={drugInfo.drug.name + '_' + index} className='trade-name d-inline'>
                    <span>{t}</span>
                  </li>
                ))
              ) : (
                <li className='trade-name d-inline'>
                  <span>None</span>
                </li>
              )}
            </ul>
          </section>
        </Modal.Body>
        <Modal.Footer className='gap-2'>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
