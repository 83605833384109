import { ORDER_STATUS_MAPPING } from '@constants/constants';
import { ResultsType } from '@customTypes/app';
import { OrderStatus, ToplineResult } from '@customTypes/order';
import { CaseRequisition, Requisition } from '@customTypes/requisition';

import { getResultType } from './common';

export const getRequisitionName = (requisition: Requisition | CaseRequisition): string => {
  return requisition.name.middle
    ? `${requisition.name.first} ${requisition.name.middle} ${requisition.name.last}`
    : `${requisition.name.first} ${requisition.name.last}`;
};

export const getRequisitionStatusKey = (requisition?: Requisition | null): OrderStatus | null => {
  const orders = requisition?.orders || [];
  const statusKeys = Object.keys(ORDER_STATUS_MAPPING).reverse();

  if (!orders.length) return null;

  for (const status of statusKeys) {
    if (
      orders.every(
        order => ORDER_STATUS_MAPPING[order.status].weight >= ORDER_STATUS_MAPPING[status as OrderStatus].weight,
      )
    ) {
      return status as OrderStatus;
    }
  }

  return null;
};

export const getRequisitionStatus = (requisition?: Requisition | null): string | null => {
  const status = getRequisitionStatusKey(requisition);

  if (status) {
    return ORDER_STATUS_MAPPING[status].label;
  }

  return null;
};

export const getRequisitionResult = (requisition?: Requisition | null): ResultsType => {
  let results: ToplineResult[] = [];

  (requisition?.orders || []).forEach(order => {
    if (order.results?.length) {
      results = results.concat(order.results);
    }
  });

  return getResultType(results);
};
