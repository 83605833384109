import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFamily } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { startCase, kebabCase } from 'lodash';

import { CaseRequisition } from '@customTypes/requisition';
import { getRequisitionName } from '@helpers/requisition';

interface IProps {
  requisitions: CaseRequisition[];
}

export default function RelatedOrders({ requisitions }: IProps) {
  const navigate = useNavigate();

  if (!requisitions.length) return null;

  const onPatientAction = (patientId: string) => {
    navigate(`/patients/${patientId}`);
  };

  return (
    <div className='related-orders-wrapper'>
      <div className='d-flex align-items-center pt-1 pb-2 gap-1 related-orders-title'>
        <FontAwesomeIcon icon={faFamily as IconDefinition} fontSize={16} role='button' />
        <p className='fs-body fw-medium mb-0'>Related order(s):</p>
      </div>

      <ul className='d-flex gap-2 flex-wrap list-style-none mb-0 ps-0'>
        {requisitions.map(requisition => (
          <li key={requisition.requisition_id} className='bg-tertiary rounded-1 py-1 px-1p5'>
            <p className='fs-xs fw-bold mb-0 text-left'>{startCase(kebabCase(requisition.relationship))}</p>
            <div className='d-flex gap-2'>
              <Button
                variant='link'
                className='p-0 border-0 fs-body text-decoration-none fw-medium'
                onClick={() => onPatientAction(requisition.individual_id)}
              >
                {getRequisitionName(requisition)}
              </Button>
              <span className='fs-body'>({requisition.requisition_id})</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
