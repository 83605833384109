import { Button, Stack } from 'react-bootstrap';
import { faRotate } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@components/Button/IconButton';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import { SUPPORT_EMAIL } from '@constants/constants';

interface RequisitionsStatusPanelProps {
  loading: boolean;
  loadingError: string | null;
  onRetry: () => void;
}

export default function RequisitionsStatusPanel({ loading, loadingError, onRetry }: RequisitionsStatusPanelProps) {
  return (
    <div className='requisitions-status-panel'>
      {loading && (
        <div className='w-100' role='presentation'>
          <p className='fs-5 fw-medium lh-sm'>Loading...</p>
          <ProgressBar />
        </div>
      )}
      {loadingError && (
        <Stack gap={3}>
          <h5 className='fs-5 lh-sm fw-medium'>Unable to load requisition data</h5>
          <p className='lh-base fs-6 mb-0'>
            <span className='d-block'>Please retry or return later.</span>
            <span className='d-block'>
              <span className='d-inline-block align-middle'>If the problem recurs, please contact</span>
              <Button
                variant='link'
                className='p-0 border-0 text-dark ms-1'
                href={`mailto:${SUPPORT_EMAIL}`}
                target='_blank'
              >
                {SUPPORT_EMAIL}.
              </Button>
            </span>
            <span className='d-block'>We’re sorry for the inconvenience.</span>

            <small className='d-inline-block fs-body fw-bold my-2'>500: Internal Server Error</small>
          </p>

          <IconButton startIcon={faRotate} className='align-self-center' variant='primary' size='lg' onClick={onRetry}>
            Retry
          </IconButton>
        </Stack>
      )}
    </div>
  );
}
