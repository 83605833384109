import { Accordion, Badge, Stack } from 'react-bootstrap';
import { FC } from 'react';
import PGxGuidanceBadge from './PGxGuidanceBadge';
import { Annotation, RecommendationAnnotation } from '@customTypes/drugs';

interface RecommendationProps {
  recommendation: RecommendationAnnotation;
}
const Recommendation: FC<RecommendationProps> = ({ recommendation }) => {
  return (
    <>
      <Stack direction='horizontal' className='mb-3'>
        <p className='fs-sm fw-medium text-body-emphasis mb-0 me-2'>Genotypes:</p>
        <ul className='annotation-genotype-list'>
          {Object.entries(recommendation.genotypes).map(entry => (
            <Badge key={`${recommendation.id}${entry[0]}`} as='li' bg='tertiary' text='secondary-emphasis'>
              <span>{entry[0]}: </span>
              <span>{entry[1]}</span>
            </Badge>
          ))}
        </ul>
      </Stack>
      <p className='fs-sm fw-medium text-body-emphasis mb-2'>Recommendation:</p>
      <div dangerouslySetInnerHTML={{ __html: recommendation.text.html }} className='annotation-inner-html' />
    </>
  );
};

interface IProps {
  annotation: Annotation;
}

export default function PGxGuidanceAccordion({ annotation }: IProps) {
  return (
    <>
      {annotation.multipopulation ? (
        <Accordion as='ul' id={annotation.id} className='annotation-accordion'>
          {annotation.recommendationAnnotations.map(r => (
            <Accordion.Item key={'rec_' + r.id} as='li' eventKey={r.id}>
              {(r.dosingInformation || r.alternateDrugAvailable || r.otherPrescribingGuidance) && (
                <div className='guidance-badge-row'>
                  {r.dosingInformation && <PGxGuidanceBadge guidanceType='dosingInformation' />}
                  {r.alternateDrugAvailable && <PGxGuidanceBadge guidanceType='alternateDrugAvailable' />}
                  {r.otherPrescribingGuidance && <PGxGuidanceBadge guidanceType='otherPrescribingGuidance' />}
                </div>
              )}
              <Accordion.Header as='div'>{r.population ? r.population : r.name}</Accordion.Header>
              <Accordion.Body>
                <Recommendation recommendation={r} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        <ul id={annotation.id} className='annotation-single-pop mb-0'>
          {annotation.recommendationAnnotations.map(r => (
            <li key={'rec_' + r.id}>
              {(r.dosingInformation || r.alternateDrugAvailable || r.otherPrescribingGuidance) && (
                <div className='guidance-badge-row'>
                  {r.dosingInformation && <PGxGuidanceBadge guidanceType='dosingInformation' />}
                  {r.alternateDrugAvailable && <PGxGuidanceBadge guidanceType='alternateDrugAvailable' />}
                  {r.otherPrescribingGuidance && <PGxGuidanceBadge guidanceType='otherPrescribingGuidance' />}
                </div>
              )}
              <div className='single-pop-header'>{r.population ? r.population : r.name}</div>
              <div className='single-pop-body'>
                <Recommendation recommendation={r} />
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
