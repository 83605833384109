import Badge from 'react-bootstrap/Badge';

interface PGxGuidanceBadgeProps {
  guidanceType: 'dosingInformation' | 'alternateDrugAvailable' | 'otherPrescribingGuidance' | string;
}

export default function PGxGuidanceBadge({ guidanceType }: PGxGuidanceBadgeProps) {
  const badgeText =
    guidanceType === 'dosingInformation'
      ? 'Dosing'
      : guidanceType === 'alternateDrugAvailable'
        ? 'Alt. Medication'
        : guidanceType === 'otherPrescribingGuidance'
          ? 'Other'
          : guidanceType;
  const badgeBgColor =
    guidanceType === 'dosingInformation'
      ? 'myoBlue-subtle'
      : guidanceType === 'alternateDrugAvailable'
        ? 'myoTeal-subtle'
        : guidanceType === 'otherPrescribingGuidance'
          ? 'warning-subtle'
          : 'primary-subtle';
  const badgeTextColor =
    guidanceType === ('dosingInformation' || 'alternateDrugAvailable' || 'otherPrescribingGuidance')
      ? 'black'
      : 'primary-emphasis';

  return (
    <Badge bg={badgeBgColor} text={badgeTextColor} className='guidance-badge'>
      {badgeText}
    </Badge>
  );
}
