import { useMemo, useState } from 'react';

import { Button, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

import catalystLogoImg from '@assets/images/catalyst-logo.png';
import { ReactComponent as FullLogoSvg } from '@assets/images/full_logo.svg';
import { CATALYST_CAMPAIGN_CODE } from '@constants/constants';
import { Campaign } from '@customTypes/campaign';
import { Product } from '@customTypes/product';
import usePatientDetail from '@hooks/usePatientDetail';

interface IProps {
  campaign?: Campaign;
  products: Product[];
}

export default function NewReportCard({ campaign, products }: IProps) {
  const navigate = useNavigate();
  const params = useParams();
  const [showAll, setShowAll] = useState(false);

  const patientId = useMemo(() => params?.id, [params]);
  const isCatalyst = useMemo(() => campaign?.campaign_code === CATALYST_CAMPAIGN_CODE, [campaign?.campaign_code]);

  const { firstCompletedRequisition } = usePatientDetail(patientId);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const orderNewReport = () => {
    navigate(
      // eslint-disable-next-line max-len
      `/patients/${patientId}/order-new-report/${firstCompletedRequisition?.requisition_id}?campaign=${campaign?.campaign_code ?? ''}`,
    );
  };

  return (
    <Card className='border report-card' bg='white'>
      <Card.Body className='d-flex flex-column align-items-start gap-3 bg-white'>
        <div className='d-flex justify-content-center my-2 w-100 report-logo'>
          {!!campaign && !isCatalyst && <span className='lh-xs fw-bold fs-4 campaign-name'>{campaign.name}</span>}
          {!campaign && <FullLogoSvg height={50} />}
          {isCatalyst && <img src={catalystLogoImg} alt='Catalyst Logo' height={50} />}
        </div>
        <Button className='w-100' onClick={orderNewReport}>
          Order New Report
        </Button>
        <div className='hr' />
        <div className='text-left'>
          <p className='mb-0 fs-xs fw-bold'>Available product(s):</p>
          <ul className='my-2 report-products'>
            {products.map((product, index) => (
              <li key={product.product_id} className={!showAll && index > 3 ? 'sr-only fs-xs' : 'fs-xs'}>
                {product.name ?? ''}
              </li>
            ))}
          </ul>
          {products.length > 4 ? (
            <Button variant='link' size='sm' className='ps-0 report-products-toggle' onClick={handleShowAll}>
              {showAll ? 'less' : `(${products.length - 4}) more...`}
            </Button>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
}
